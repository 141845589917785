@media (max-width: 980px) {
  .errorMessage {
    margin-left: auto !important;
    width: auto !important;
    height: auto !important;
    padding: 5px;
  }

  .numberDescription {
    margin-left: 300px !important;
    height: auto !important;
    width: 250px !important;
  }
}

@media (max-width: 740px) {
  .stageHeader {
    background-size: 100% !important;
  }
}

@media (max-width: 520px) {
  .stageHeader {
    background-size: 180% !important;
    background-position-x: 50%;

    background-position-y: -30%;
    background-image: url("../src/Assets/icons/resurce.png");
  }

  .selectVariants {
    left: 100px !important;
  }

  .numberDescription {
    margin-left: 140px !important;
  }
}

@media (max-width: 400px) {
  .contactingTitle {
    font-size: 1.1rem !important;
  }

  .stageHeaderText {
    font-size: 0.9rem !important;
  }

  .stageHeader {
    background-position-x: 0%;

    background-position-y: -100% !important;
    background-size: 190% !important;
  }

  .numberDescription {
    margin-left: auto !important;
    margin-right: 5px !important;
  }
}

@media (max-width: 651px) {
  .selectVariants {
    width: 200px !important;
    font-size: 1rem !important;
    left: 120px !important;
  }

  .stageBody {
    width: 98% !important;
  }

  .contactingTitle {
    width: auto !important;
  }

  .stageHeader {
    margin: 0 !important;
  }

  .stageTitleBox {
    flex-direction: column !important;
    padding: 0 !important;
  }

  .stageHeaderText {
    width: auto !important;
  }

  .stageHeaderNumber {
    margin: auto !important;
    margin-top: 10px !important;
  }

  .questionSelect,
  .questionInput {
    width: 140px !important;
    height: auto !important;
  }

  .questionSelect {
    font-size: 12px !important;
  }

  .questionInput {
    font-size: 16px !important;
  }

  .questionInputNext {
    font-size: 12px !important;
  }

  .questionText {
    font-size: 14px !important;
  }

  .numberDescription {
    font-size: 14px !important;
  }

  .questionHalf {
    width: 50% !important;
  }

  .approveContainer {
    margin-top: -25px !important;
  }

  .approveBox {
    margin-left: 10px !important;
  }

  .approveContent {
    width: 330px !important;
  }

  .questionContent {
    padding-left: 15px !important;
  }

  .nextQuestionContent {
    align-items: center !important;
  }

  .nextCheckmark {
    margin-top: -10px !important;
  }

  .questionBox {
    margin-left: 0 !important;
  }

  .questionText {
    width: 138px !important;
  }

  .finalMessage {
    width: 360px !important;
    height: 650px !important;
    padding-bottom: 0 !important;
  }

  .finalMessageTitleBox {
    height: 120px !important;
  }

  .finalMessageTitle {
    font-size: 0.8rem !important;
  }

  .thankfullTitle {
    font-size: 0.8rem !important;
  }

  .personalCode {
    font-size: 35px !important;
  }

  .finalText {
    padding: 5px !important;
  }

  .finalText p {
    font-size: 0.8rem !important;
  }

  .selectRegion {
    margin-top: 4px !important;
    height: 41px !important;
  }

  .phoneInput {
    max-width: 100px !important;
  }
}

@media (max-width: 361px) {
  .stageHeader {
    background-position-x: 0%;

    background-position-y: 100% !important;
    background-size: 180% !important;
  }

  .questionSelect,
  .questionInput {
    width: 135px !important;
    padding: 10px !important;
  }

  .selectRegion {
    height: 31px !important;
  }

  .nextCheckmark {
    margin-left: 5px !important;
  }

  .questionContent {
    padding-left: 5px !important;
  }

  .questionBox {
    margin-left: 0 !important;
  }

  .questionText {
    width: 120px !important;
  }

  .selectVariants {
    width: 190px !important;
    left: 80px !important;
  }

  .approveBox {
    margin: 0 !important;
  }

  .approveContent {
    width: 95% !important;
  }

  .finalMessage {
    width: 300px !important;
    height: 700px !important;
    padding-bottom: 10px !important;
  }

  .finalMessageTitleBox {
    height: 100px !important;
  }

  .phoneInput {
    padding-left: 60px !important;
    max-width: 90px !important;
  }
}

body {
  margin: 0;
  margin-top: 10px;
  font-family: "Evolventa", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.stageBody {
  margin: 0 auto;
  background-color: #1d6ac5;
  width: 80%;
  height: 400px;
  border-radius: 20px;
}

.stageContent {
  background-color: #e4eaf3;
  padding-bottom: 30px;
  border-radius: 20px;
  padding-top: 30px;
  z-index: 3;
}

.stageHeader {
  margin-left: 20px;
  padding: 20px;
  /* background-image: url("../src/Assets/icons/intro-group-right.png"); */
  background-image: url("../src/Assets/icons/resurce.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.contactingTitle {
  font-size: 25px;
  color: white;
  padding: 10px;
  width: 35%;
  padding-left: 50px;
  background-color: #033883;
  border-radius: 20px;
}

.stageTitleBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 15px;
}

.stageHeaderText {
  font-size: 18px;
  font-weight: bold;
  width: 50%;
  color: white;
}

.stageHeaderNumber {
  background-color: #ff7900;
  color: white;
  border-radius: 20px;
  padding: 10px;
  width: 150px;
  height: 30px;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
}

.questionBox {
  margin-top: 20px;
  margin-left: 30px;
}

.questionText {
  display: flex;
  align-items: center;
  font-weight: bold;
  height: 100%;
}

.questionContent {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.questionHalf {
  width: 25%;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
}

.questionSelect,
.questionInput {
  background-color: white;
  border-radius: 30px;
  width: 380px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 15px;
  padding-left: 15px;
}

.questionInput {
  height: 21px;
  border: none;
}

.questionInputNext {
  height: auto;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-top: -5px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  left: -6px;
  height: 25px;
  width: 25px;
  background-color: #ffffff;
  border-radius: 25px;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

.container input:disabled ~ .checkmark {
  background-color: #ccc;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  top: 5px;
  left: 9px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.selectVariants {
  z-index: 100;
  position: absolute;
  border-radius: 15px;
  padding: 10px;
  margin-top: 30px;
  width: 320px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f4f4f4;
}

.selectVariants div {
  padding: 5px;
  border-radius: 10px;
  margin-top: 5px;
}

.selectVariants div:hover {
  background-color: #e7e7e7;
  cursor: pointer;
}

.approveBox {
  font-weight: bold;
}

.approveContent {
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 544px;
  padding: 5px;
}

.nextCheckmark {
  margin-top: 15px;
}

.approveContainer {
  margin-top: -25px;
}

.approveContainer .checkmark {
  background-color: #ccc;
  margin-left: 15px;
}

.phoneBox {
  display: flex;
  flex-direction: row;
}

.selectRegion {
  position: absolute;
  border: none;
  margin-top: 3px;
  margin-left: 3px;
  height: 45px;
  border-radius: 30px;
}

.phoneInput {
  padding-left: 60px;
}

.numberDescription {
  padding-left: 15px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #1d6ac5;
  color: white;
  border-radius: 30px;
  border-top-left-radius: 0px;
  width: 350px;
  font-weight: bold;
  margin-top: 10px;
  margin-left: 400px;
}

.nextStageButtonBox {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.nextStageButton {
  background-color: #033883;
  color: white;
  width: 180px;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 5px;
  cursor: pointer;
}

.popUp {
  background: rgba(102, 102, 102, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
  overflow-y: auto !important;
  z-index: 100;
}

.finalMessage {
  border-radius: 20px;
  background-color: white;
  height: 780px;
  padding-bottom: 20px;
  margin-bottom: 50px;
  width: 700px;
}

.finalMessageTitleBox {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.finalMessageTitle {
  font-size: 25px;
  border-radius: 30px;
  padding: 10px;
  width: 80%;
  text-align: center;
  color: white;
  background-color: #033883;
}

.thankfullTitle {
  font-size: 25px;
  margin-top: 20px;
}

.finalMessageContent {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
}

.errorBox {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.errorMessage {
  margin-left: 450px;
  width: 380px;
  color: white;
  font-weight: bold;
  background-color: #ed1d45;
  height: 30px;
  border-radius: 30px;
  border-top-left-radius: 0px;
  align-content: center;
  text-align: center;
}

.personalCodeBox {
  border-right: solid 2px #033883;
}

.personalCode {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 80px;
  text-align: center;
  font-weight: bold;
  color: #033883;
}

.finalText {
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  font-size: 18px;
}

.pontsRef {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

a {
  color: #033883;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}

a:link {
  color: #033883;
}

a:visited {
  color: #3b72b8;
}

a:focus {
  color: #3b71b898;
}

a:hover {
  color: #3b71b898;
}

a:active {
  color: #3b71b898;
}
